export const createService = {
    SAVE_CREATE_SERVICE_DATA : 'SAVE_CREATE_SERVICE_DATA',
    REMOVE_CREATE_SERVICE_DATA: 'REMOVE_CREATE_SERVICE_DATA'
}
export const serviceCard = {
    SAVE_FETCHED_SERVICE_DATA : 'SAVE_FETCHED_SERVICE_DATA',
    SAVE_NAVIGATION_DATA : 'SAVE_NAVIGATION_DATA',
    REMOVE_NAVIGATION_DATA: 'REMOVE_NAVIGATION_DATA',
    REMOVE_FETCHED_SERVICE_DATA: 'REMOVE_FETCHED_SERVICE_DATA',
    SAVE_ALL_HOSTED_SERVICE_DATA : 'SAVE_ALL_HOSTED_SERVICE_DATA',
    GET_SERVICE_CARD_ID_FOR_FROM_SELLER_DASHBOARD: 'GET_SERVICE_CARD_ID_FOR_FROM_SELLER_DASHBOARD',
    REMOVE_SERVICE_CARD_ID_FOR_FROM_SELLER_DASHBOARD :'REMOVE_SERVICE_CARD_ID_FOR_FROM_SELLER_DASHBOARD'
}

export const getSellerId = {
    GET_SELLER_ID_FROM_AUTH : 'GET_SELLER_ID_FROM_AUTH',
    GET_SELLER_ID_FROM_BUY_SERVICE: 'GET_SELLER_ID_FROM_BUY_SERVICE',
    GET_SELLER_ID_FROM_SELLER_CARD: 'GET_SELLER_ID_FROM_SELLER_CARD',
    REMOVE__SELLER_ID_FROM_AUTH : 'REMOVE__SELLER_ID_FROM_AUTH',
    REMOVE_SELLER_ID_FROM_BUY_SERVICE : 'REMOVE_SELLER_ID_FROM_BUY_SERVICE',
    REMOVE_SELLER_ID_FROM_SELLER_CARD: 'REMOVE_SELLER_ID_FROM_SELLER_CARD'

}

export const getUserId = {
    GET_USER_ID: 'GET_USER_ID',
    REMOVE_USER_ID: 'REMOVE_USER_ID'
}

export const getPayId={
    GET_PAY_ID:'GET_PAY_ID',
    REMOVE_PAY_ID:'REMOVE_PAY_ID'
}
export const Getblogs = {
    GET_ALL_BLOGS: 'GET_ALL_BLOGS',
    REMOVE_ALL_BLOGS: 'REMOVE_ALL_BLOGS',
}
export const GetblogsId = {
    GET_ID_BLOGS: 'GET_ID_BLOGS',
    REMOVE_ID_BLOGS: 'REMOVE_ID_BLOGS',
    GET_FROM_FORM:'GET_FROM_FORM',
    REMOVE_FROM_FORM:'GET_FROM_FORM'
}

export const getAllSellers = {
    GET_ALL_SELLER_CARDS :'GET_ALL_SELLER_CARDS',
    REMOVE_ALL_SELLER_CARDS: 'REMOVE_ALL_SELLER_CARDS'
}

export const getSellerDashboardCardsData = {
    GET_SELLER_HOSTED_SERVICES : 'GET_SELLER_HOSTED_SERVICES',
    GET_SELLER_DRAFTED_SERVICES : 'GET_SELLER_DRAFTED_SERVICES',
    GET_SELLER_ONGOING_ORDERS : 'GET_SELLER_ONGOING_ORDERS',
    REMOVE_SELLER_HOSTED_SERVICES : 'REMOVE_SELLER_HOSTED_SERVICES',
    REMOVE_SELLER_DRAFTED_SERVICES : 'REMOVE_SELLER_DRAFTED_SERVICES',
    REMOVE_SELLER_ONGOING_ORDERS : 'REMOVE_SELLER_ONGOING_ORDERS'
}