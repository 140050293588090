import { useEffect } from 'react';
import './App.css'
import { TypeAnimation } from "react-type-animation";
function App() {
  useEffect(() => {
    // Redirect to another website after 2 seconds
    const redirectTimer = setTimeout(() => {
      window.location.href = 'https://www.youthbuzz.in';
    }, 0);

    // Clear the timer if the component unmounts before the timeout
    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <div style={{textAlign:"center",paddingTop:"20px"}}>
    {/* <TypeAnimation
    sequence={[
      // Same substring at the start will only be typed out once, initially
      "INITIATING....",

      1000,
      // wait 1s before replacing "Mice" with "Hamsters"
    ]}


    className="purple_text"
    wrapper="span"
    speed={30}
    style={{
      fontSize: "1.5em",

      display: "inline-block",
      textAlign:"center",
      color: "white",
      fontWeight: "bolder",
      fontFamily: "Poppins,sans-serif",
    }}
    repeat={Infinity}
  /> */}
  </div>
  ); // or any component you want to render
}

export default App;